import React, { useContext, useCallback, useState } from 'react';
import { Controller, Scene } from 'react-scrollmagic';
// components
import { BackgroundContext } from './Background';

function SectionTrigger({ bgColor, children, className, style, textColor, threshold }) {
  const { setBgColor, setTextColor } = useContext(BackgroundContext);
  const [height, setHeight] = useState('100%');
  const ref = useCallback(node => {
    if (node !== null) {
      setHeight(node.scrollHeight);
    }
  }, []);
  return (
    <>
      <Controller>
        <Scene duration={height}>
        {(_, event) => {
            if (event.type === 'enter') {
              setBgColor(bgColor);
              setTextColor(textColor);
            }
            return (
              <section className={className} style={style} ref={ref}>
                {children}
              </section>
            );
          }}
        </Scene>
      </Controller>
    </>
  );
}

export default SectionTrigger;
